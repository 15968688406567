import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import apiClient from "../Config/apiConfig";
import { LineWave } from "react-loader-spinner";
import { Link } from "react-router-dom";

const config = {
  BASE_URL: "https://admin.motivusi.com",
  IMAGES_URL: process.env.NODE_ENV === 'development' 
              ? "http://localhost:3000/images" 
              : "https://motivusi.com/images",
};
console.log('Image URL:', `${config.IMAGES_URL}/DipanjanMukherjee.jpeg`);

const membersData = {
  membersMtipl: {
    membersItem: [
      { id: 1, name: "Dipanjan Mukherjee", designation: "CEO", image: `${config.IMAGES_URL}/DipanjanMukherjee.jpeg` },
      { id: 2, name: "Ravi Shankar", designation: "CTO", image: `${config.IMAGES_URL}/RaviShankar.jpeg` },
      { id: 3, name: "Farhan Ahmed", designation: "CFO", image: `${config.IMAGES_URL}/FarhanAhmed.jpg` }
    ],
  },
  membersMinpl: {
    membersItem: [
      { id: 1, name: "Dipanjan Mukherjee", designation: "CEO", image: `${config.IMAGES_URL}/DipanjanMukherjee.jpeg` },
      { id: 2, name: "Ravi Shankar", designation: "CTO", image: `${config.IMAGES_URL}/RaviShankar.jpeg` },
      { id: 3, name: "Preeti Varshney", designation: "CFO", image: `${config.IMAGES_URL}/PreetiVarshney.jpeg` }
    ],
  },
  membersCstpl: {
    membersItem: [
      { id: 1, name: "Dipanjan Mukherjee", designation: "CEO", image: `${config.IMAGES_URL}/DipanjanMukherjee.jpeg` },
      { id: 2, name: "Ravi Shankar", designation: "CTO", image: `${config.IMAGES_URL}/RaviShankar.jpeg` },
      { id: 2, name: "Anshul Mathur", designation: "CTO", image: `${config.IMAGES_URL}/AnshulMathur.jpg` }
    ],
  },
  membersMippl: {
    membersItem: [
      { id: 1, name: "Dipanjan Mukherjee", designation: "CEO", image: `${config.IMAGES_URL}/DipanjanMukherjee.jpeg` },
      { id: 2, name: "Ravi Shankar", designation: "CTO", image: `${config.IMAGES_URL}/RaviShankar.jpeg` },
      { id: 3, name: "Preeti Varshney", designation: "CFO", image: `${config.IMAGES_URL}/PreetiVarshney.jpeg` }
    ],
  },
};

const getMembers = (productId) => {
  switch (productId) {
    case "12":
      return membersData.membersMtipl.membersItem;
    case "14":
      return membersData.membersMinpl.membersItem;
    case "13":
      return membersData.membersCstpl.membersItem;
    case "11":
      return membersData.membersMippl.membersItem;
    default:
      return []; // Return an empty array if no match is found
  }
};


const ProductDetails = () => {
  const { id } = useParams();
  const [companyDetails, setCompanyDetails] = useState(null);
  const [loader, setLoader] = useState(true);

  const fetchCompanyDetails = async () => {
    try {
      const res = await apiClient.get(`api/products/${id}`);
      console.log("Product ID:", id);
      console.log(res.data); // Log the response to inspect
      setCompanyDetails(res.data.data);
      setLoader(false);
    } catch (error) {
      console.log("API error:", error.message);
      setLoader(false); // Stop loader if there's an error
    }
  };

  useEffect(() => {
    fetchCompanyDetails();
  }, [id]);

  if (loader) {
    return (
      <div className="d-flex justify-content-center">
        <LineWave height="250" width="150" color="#56b13d" ariaLabel="line-wave" />
      </div>
    );
  }

  const managementMembers = companyDetails?.management?.length 
    ? companyDetails.management 
    : getMembers(id);

  return (
    <>
    
    <div className="title-bar-wrapper">
        <Container>
          <Row>
            <div className="title">
              <h1>Company Profile</h1>
            </div>
            <ul className="bread-crumb pull-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>-</li>
              <li>company</li>
            </ul>
          </Row>
        </Container>
      </div>
      <div style={{height:'100px'}}></div>
    <Container>
      {companyDetails && (
        <>
          <Row>
            <Col>
              <h1 className="mb-5">{companyDetails.name}</h1>
              <p>{companyDetails.desc}</p>
            </Col>
          </Row>

          <Row className="my-4">
            <Col>
              <h2 className="mb-5">Management</h2>
              <Row>
                {managementMembers.map((member) => (
                  <Col md="3" key={member.id} className="mb-4">
                    <img
                      src={member.image}
                      alt={member.name}
                      className="img-fluid rounded-circle mb-2"
                      style={{ width: "100px", height: "100px" }}
                    />
                    <h5>{member.name}</h5>
                    {/* <p>{member.designation}</p> */}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          {companyDetails.product_faqs && companyDetails.product_faqs.length > 0 && (
            <Row>
              <Col>
                <h2 className="mb-5">Our Services</h2>
                <Accordion defaultActiveKey="0">
                  {companyDetails.product_faqs.map((faq, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>{faq.question}</Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex gap-5">
                            <div className="w-25"><img src={`${config.BASE_URL}/uploads/product/${faq.image}`} alt="" className="w-100"/></div>
                            <div style={{width:'70%'}}>{faq.answer}</div>  
                        </div>                             
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
    <div style={{height:'150px'}}></div>
    </>
  );
};

export default ProductDetails;
