import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { AboutUsImg, Years,MotivusAboutusInnerPage } from "../images";

const AboutUs = () => {
  return (
    <>
      <div className="title-bar-wrapper">
        <Container>
          <Row>
            <div className="title">
              <h1>About Us</h1>
            </div>
            <ul className="bread-crumb pull-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>-</li>
              <li>About</li>
            </ul>
          </Row>
        </Container>
      </div>
      <div className="about-sec" style={{ padding: "70px 0px 130px" }}>
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="about-img">
                <img src={MotivusAboutusInnerPage} alt="" />
                {/* <img className="years" src={Years} alt="" /> */}
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="about_content">
                <h3 className="heading">
                  <span>About Us</span>
                </h3>
                {/* <h2>Driving Change As A Disruptor</h2> */}
                <p>
                Motivation Innovation group is a Technology Innovation Company, invested in Research & Development and Manufacturing to contribute in the Environment, Sustainability and Governance sector Globally.
                <br/><br/>
                The founders and management come with substantial experience in the fields of Telecommunications, Power Electronics, Fiber and Software. The management has acquired expertise not only in India, but also in other Southeast Asian countries, such as Malaysia, Myanmar, Bangladesh, Pakistan, Cambodia, Indonesia, Sri Lanka and the Philippines.
                </p>
                
                <h1 className="sub-heading">
                  <span>About</span>
                </h1>
              </div>
            </Col>
            
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
